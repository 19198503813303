import { getCmsCollection } from 'lib/nahdi-middleware';
import { FooterType } from 'types/cms/footer';
import { getTextByLang } from 'utils';
import { LanguageCodesType, RegionCodesType } from 'utils';

export const getFooterMegaMenu = async (
  language: LanguageCodesType,
  region: RegionCodesType
): Promise<FooterType> => {
  const data = await getCmsCollection(
    'fetch-footer-mega-menu',
    '',
    language,
    region
  );
  const dataWidget = data.template[0].widgets;
  return {
    footerColumn: dataWidget
      .find((widget) => widget.component_type === 'static-pages-block')
      ?.megamenu_item?.map((megamenuItem) => {
        return {
          columnItem: megamenuItem.megamenu_item_list?.map((item) => {
            return {
              heading: item.label,
              link: item.link,
              linkType: item.link_type,
              calculated_relative_url: item?.calculated_relative_url,
            };
          }),
          heading: megamenuItem.label,
        };
      }),
    footerSocial: {
      title: getTextByLang(
        dataWidget.find(
          (widget) => widget.component_type === 'social-media-handles-block'
        )?.header,
        language
      ),
      footerSocialItems: dataWidget
        .find(
          (widget) => widget.component_type === 'social-media-handles-block'
        )
        ?.items?.map((megamenuItem) => {
          return {
            icon: megamenuItem.image,
            link: megamenuItem.link,
            linkType: megamenuItem.link_type,
          };
        }),
    },
    footerPopularCategories: dataWidget
      .find((widget) => widget.component_type === 'popular-categories-block')
      ?.megamenu_item?.map((megamenuItem) => {
        return {
          columnItem: megamenuItem.megamenu_item_list?.map((item) => {
            return {
              heading: item.label,
              link: item.link,
              linkType: item.link_type,
              calculated_relative_url: item?.calculated_relative_url,
            };
          }),
          heading: megamenuItem.label,
          link: megamenuItem.link,
          linkType: megamenuItem.link_type,
          calculated_relative_url: megamenuItem?.calculated_relative_url,
        };
      }),
    appstoreBlock: dataWidget
      .find((widget) => widget.component_type === 'app-store-block')
      ?.items?.map((megamenuItem) => {
        return {
          icon: megamenuItem.image,
          link: megamenuItem.link,
          linkType: megamenuItem.link_type,
        };
      }),
    footerPaymentMethods: dataWidget
      .find((widget) => widget.component_type === 'website-sub-footer-block')
      ?.payment_methods?.items?.map((megamenuItem) => {
        return {
          image: megamenuItem.image,
        };
      }),
    vatRegistryLabel: getTextByLang(
      dataWidget.find(
        (widget) => widget.component_type === 'website-sub-footer-block'
      )?.vat_registry_label,
      language
    ),
    copyrightLabel: getTextByLang(
      dataWidget.find(
        (widget) => widget.component_type === 'website-sub-footer-block'
      )?.copyright_label,
      language
    ),
    logo: dataWidget.find(
      (widget) => widget.component_type === 'website-sub-footer-block'
    )?.footer_logo.image,
    staticLinks: dataWidget
      .find((widget) => widget.component_type === 'website-sub-footer-block')
      ?.static_links?.map((staticLinkItem) => {
        return {
          heading: getTextByLang(staticLinkItem.label, language),
          link: staticLinkItem.link,
          linkType: staticLinkItem.link_type,
        };
      }),
    taxIcons: dataWidget
      .find((widget) => widget.component_type === 'website-sub-footer-block')
      ?.tax_icons?.items.map((megamenuItem) => {
        return {
          image: megamenuItem.image,
          link: megamenuItem.link,
          linkType: megamenuItem.link_type,
        };
      }),
    colorTheme: dataWidget.find(
      (widget) => widget.component_type === 'website-sub-footer-block'
    )?.color_theme,
    language: language,
    region: region,
  };
};
