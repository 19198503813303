import { Template } from 'types/nahdi-errors';
import { getTextByLang } from 'utils';
import clsx from 'clsx';
import Image from 'next/image';
import { LanguageCodesType, RegionCodesType } from 'utils';

const RootErrorHandler = ({
  data,
  logo,
  darkLogo,
  language,
  region,
}: {
  logo?: string;
  data: Template;
  darkLogo: string | undefined;
  language: LanguageCodesType;
  region: RegionCodesType;
}) => {
  return (
    <div className='h-full ltr:font-montserrat rtl:font-tajawal'>
      <div className='relative flex h-[96px] w-full items-center justify-center lg:bg-[#2C6BA4]'>
        <Image
          className='hidden lg:block'
          src={logo ?? ''}
          height={93}
          width={153}
          alt='logo'
        />
        <Image
          className='lg:hidden'
          src={darkLogo ?? ''}
          height={93}
          width={153}
          alt='logo'
        />
      </div>
      <div style={{ backgroundColor: data.color_theme.background_color }}></div>
      <div
        className={clsx(
          `relative mx-auto flex flex-col items-center justify-center overflow-clip px-4 py-8 md:max-w-[592px] lg:min-h-[600px]`
        )}
      >
        <div className='flex flex-col gap-4 lg:flex-row-reverse'>
          <div className='relative flex w-full items-center justify-center lg:w-auto'>
            <Image
              src={data.component_properties.icon}
              alt={getTextByLang(data.component_properties.header, language)}
              width={150}
              height={150}
            />
          </div>
          <div className='lg:flex-1'>
            <p
              className='text-center text-xl lg:text-start lg:text-2xl'
              style={{ color: data.color_theme.header_text_color }}
            >
              {getTextByLang(data.component_properties.header, language)}
            </p>
            <p
              className='mt-3 w-full max-w-[440px] text-center text-custom-base !leading-loose lg:text-start lg:text-custom-lg'
              style={{ color: data.color_theme.label_text_color }}
            >
              {getTextByLang(data.component_properties.label, language)}
            </p>
          </div>
        </div>
        <div className='my-8 h-[1px] w-full border border-dotted border-[#BDBDBD] lg:border-solid' />
        {data.component_properties.item_list && (
          <div className='w-full text-start'>
            <p
              className='mb-6 text-custom-sm font-semibold lg:text-custom-lg'
              style={{ color: data.color_theme.item_list_header_text_color }}
            >
              {getTextByLang(
                data.component_properties.item_list.header,
                language
              )}
            </p>
            <ul role='list' className='flex flex-col space-y-4'>
              {data.component_properties.item_list.items.map((item, i) => (
                <li key={i} className='flex items-center gap-3'>
                  <div className='relative'>
                    <Image
                      src={item.icon}
                      alt={getTextByLang(item.label, language)}
                      width={20}
                      height={20}
                    />
                  </div>
                  <p
                    className='flex-1 text-custom-sm lg:text-custom-base'
                    style={{
                      color: data.color_theme.item_list_header_text_color,
                    }}
                    dangerouslySetInnerHTML={{
                      __html: getTextByLang(item.label, language),
                    }}
                  ></p>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default RootErrorHandler;
