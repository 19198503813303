'use client';

import * as Sentry from '@sentry/nextjs';
import RootErrorHandler from 'components/root-error-handler';
import { getAppSettings } from 'lib/cms/nahdi-middleware/app-settings';
import { getFooterMegaMenu } from 'lib/cms/nahdi-middleware/footer-megamenu';
import { getNahdiErrorsAnd404StatThroughApi } from 'lib/nahdi-errors';
import { useEffect, useState } from 'react';
import { MessageTypeEnum, Template } from 'types/nahdi-errors';
import { LanguageCodesType, RegionCodesType } from 'utils';

const messagType = MessageTypeEnum.WELL_EMBARSSING;

export default function Error({
  error,
  reset,
  language,
  region,
}: {
  error: Error & { digest?: string };
  reset: () => void;
  language: LanguageCodesType;
  region: RegionCodesType;
}) {
  const [data, setData] = useState<Template>();
  const [logo, setLogo] = useState<string | undefined>();
  const [darkLogo, setDarkLogo] = useState<string | undefined>();
  const [isFallbackEnabled, setIsFallbackEnabled] = useState(false);

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  useEffect(() => {
    const getData = async () => {
      try {
        const [data, settings, footerData] = await Promise.all([
          getNahdiErrorsAnd404StatThroughApi({ language, region }),
          getAppSettings(language, region),
          getFooterMegaMenu(language, region),
        ]);
        const template = data.template.find(
          (temp) => temp.component_properties.message_type == messagType
        );
        if (template) setData(template);
        if (settings) setLogo(settings.global_components.header.desktop_logo);
        if (footerData) setDarkLogo(footerData.logo);
        setIsFallbackEnabled(false);
      } catch (ex) {
        setIsFallbackEnabled(true);
        Sentry.captureException(ex);
      }
    };
    getData();
  }, [language, region]);

  if (!data) return null;
  return !isFallbackEnabled ? (
    <RootErrorHandler
      data={data}
      logo={logo}
      darkLogo={darkLogo}
      language={language}
      region={region}
    />
  ) : (
    <ErrorFallback reset={reset} />
  );
}
/**
 * @description If the error endpoint or web settings endpoint goes down, then we are left with no other option than to render this component instead
 * @param reset handler passed from Next JS
 * @returns JSX
 */
export const ErrorFallback = ({ reset }: { reset: () => void }) => {
  return (
    <div className='mx-auto my-4 flex max-w-xl flex-col rounded-lg border border-neutral-200 bg-white p-8 md:p-12'>
      <h2 className='text-xl font-bold'>Oh no!</h2>
      <p className='my-2'>
        There was an issue with our storefront. This could be a temporary issue,
        please try your action again.
      </p>
      <button
        className='tracking-wide mx-auto mt-4 flex w-full items-center justify-center rounded-full bg-blue-600 p-4 text-white hover:opacity-90'
        onClick={() => reset()}
      >
        Try Again
      </button>
    </div>
  );
};
